body {
  text-align: center;
  background: #083779;
  color: white;
  font-family: sans-serif;
}

h1 {
  margin-top: 25vh;
  img {
    max-width: 40vw;
  }
}
